.preview__body {
  background-color: white;
  border-radius: 10px;
  position: fixed;
  height: 60%;
  width: 20%;
}

.preview {
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
}
