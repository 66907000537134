.sidebarRow {
  display: flex;
  align-items: center;
  padding: 5px;
  flex-direction: column;
  margin: 3px 0;
  justify-content: space-between;
}

.sidebarRow:hover {
  background-color: lightgray;
  border-radius: 10px;
}

.sidebarRow > h4 {
  margin: 0;
  cursor: pointer;
}

.sidebarRow > .MuiSvgIcon-root {
  font-size: xx-large;
  color: black;
  border-radius: 30px;
}
