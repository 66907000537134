.sidebar {
  display: flex;
  margin-top: 5px;
  margin-left: 2px;
  padding: 1px 1px;
  flex: 0.002;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
}

.leftMenu__Preview {
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: row;
}
